<template>
  <div id="errors-alert"
       class="alert">
    <h5>
      Error
    </h5>
    <ul>
      <li v-for="(error,i) in errors"
          :key="i">
        {{ error }}
      </li>
    </ul>
  </div>

  <div class="banner-top">
    <div class="banner-top__header">
      <div class="banner-top__header__image">
        <img src="@/assets/img/title.png" :alt="$t('bannerTop.title')">
      </div>
      <div class="banner-top__header__infos">
        <h3 :class="[
          'banner-top__header__title',
          {
            'banner-top__header__title--margin-top':
            $i18n.locale === 'jp' ||
            $i18n.locale === 'kr'
          }
          ]"
        >
          {{ $t("bannerTopGJ.subtitle1") }}
        </h3>
        <h3>
          {{ date }}
        </h3>
        <form method="post"
                @submit="checkForm">
          <div class="mt-4 form-group">
            <div class="custum-input">
              <input v-model="email"
                      aria-label="Email"
                      type="email"
                      name="email"
                      id="email"
                      placeholder="youremail@mail.com"
                      @focusin="emptyPlaceholder()"
                      @focusout="initPlaceholder()" />
            </div>
            <button class="main-button"
                    type="submit">
              {{ $t("bannerTopGJ.button") }}
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "BannerTopGJ",
  data: function () {
    return {
      errors: [],
      email: ''
    }
  },
  computed: {
    date() {
      if (navigator.language.includes("en")) {
        return "11/08/2021 - 11/21/2021";
      } else {
        return "08/11/2021 - 21/11/2021";
      }
    }
  },
  methods: {
    getImageBg() {
      return require('../assets/img/bgBanner.png');
    },
    emptyPlaceholder: function () {
      document.getElementById('email').placeholder = ''
    },
    initPlaceholder: function () {
      document.getElementById('email').placeholder = 'youremail@mail.com'
    },
    checkForm: function (e) {
      e.preventDefault()
      document.getElementById('email').style.background = ''
      this.errors = []
      var err = 0
      let currObj = this
      if (this.email === '') {
        err = 1
        this.errors.push('Email is required.')
        document.getElementById('email').style.background = '#D4212780'
        document.getElementById('email').style.color = '#ffffff'
      }
      if (err === 0) {
        const headers = {
          'Content-Type': 'text/plain'
        }
        this.axios.post(
                'https://sandbox.pragmea.io/Webservices/src/mailerlite.php?sA=fb653c7d2f1fc6269ca02429a45e0bcf',
                { action: 'addSubscriber', email: this.email, segment: '109278461' },
                { headers }
        ).then((response) => {
          if (response.data.error) {
            currObj.errors.push(response.data.error.message)
            document.getElementById('errors-alert').style.opacity = '1'
            setTimeout(function () {
              document.getElementById('errors-alert').style.opacity = '0'
            }, 5000)
          } else {
            window.location = 'https://www.sandbox.game/' + this.$i18n.locale + '/create/game-maker/'
          }
        }).catch(function (error) {
          currObj.errors.push(error)
          document.getElementById('errors-alert').style.opacity = '1'
          setTimeout(function () {
            document.getElementById('errors-alert').style.opacity = '0'
          }, 5000)
        })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  .alert {
    position: fixed;
    top: 70px;
    right: 150px;
    width: calc(100% - 300px);
    opacity: 0;
    transition: opacity .5s;
    padding: 0 20px;
    font-size: 20px;
    color: white;
    background: rgba(212, 33, 39, 0.75);
    border-radius: 8px;
    z-index: 5;
  }
  .banner-top {
    background: $gray1;
    background-size: cover;
    background-position-x: center;
    background-position-y: bottom;
    padding-top: 100px;
    margin-top: -50px;
    position: relative;
    height: 1080px;
    z-index: 1;
    background-image: url("../assets/img/bgBanner.png");
    @include flex(row, center, center);
    &__header {
      max-width: 800px;
      text-align: center;
      margin-bottom: 50px;
      font-size: 16px;
      position: relative;
      img {
        margin-top: -100px;
        width: 800px;
        object-fit: contain;
      }
      h3 {
        margin: 0;
        font-size: 43px;
        line-height: 38px;
        text-transform: uppercase;
        -webkit-text-stroke-width: 2px;
        -webkit-text-stroke-color: black;
        font-weight: 1000;

        &:first-of-type {
          margin-top: -120px;
          font-size: 90px;
          line-height: 83px;
        }
      }
      &__title {
        &--margin-top {
          margin-top: 25px !important;
        }
      }

      form {
        margin-top: 15px;
        @include flex(row, center, center);
      }

      .custum-input input {
        background-color: transparent;
        border-radius: 2.3em;
        border: solid $white-1 1px;
        color: $white-1;
        font-size: 1em;
        text-align: center;
        width: 100%;
        padding: 8px 0;
        &::placeholder {
          color: $white-1;
          opacity: 1; /* Firefox */
        }
      }
      .custum-input {
        width: 100% !important;
      }
      .alert {
        position: fixed;
        top: 70px;
        right: 150px;
        width: calc(100% - 300px);
        opacity: 0;
        transition: opacity .5s;
      }

      .custum-input,
      .form-group {
        width: fit-content;
      }
    }
  }
  button {
    margin-top: 10px;
    display: inline-block;
    background-color: $red;
    z-index: 1;
    color: white;
    border: none;
    font-weight: bold;
    text-transform: uppercase;
    padding: 15px 62px;
    border-radius: 60px;
    font-size: 19px;
    cursor: pointer;
    text-decoration: none;
    transition: 200ms;
    &:hover {
      background-color: white;
      color: $gray2;
    }
  }
  @include breakpoint($max: 1280) {
    .banner-top {
      background-image: url("../assets/img/bgBanner800.png");
      margin-top: -10px;
      padding: 0;
      height: 1080px;
      /*background-position-y: center;*/
      &__header {
        margin-top: 0;
        height: 100%;
        @include flex(column, center, space-between);
        img {
          margin-top: 0;
          width: 445px;
          object-fit: contain;
        }
      }
    }
  }
  @include breakpoint($max: 768) {
    .banner-top {
      height: 825px;
      &__header {
        max-width: 400px;
        font-size: 15px;
        line-height: 23px;
        img {
          width: 300px;
        }
        h3 {
          &:first-of-type {
            font-size: 50px;
          }
          font-size: 32px;
          -webkit-text-stroke-width: 1px;
          line-height: 28px;
        }
      }
    }
  }
  @include breakpoint($max: 465) {
    .banner-top {
      height: 625px;
      background-image: url("../assets/img/bgBanner360.png");
      &__header {
        max-width: 400px;
        font-size: 15px;
        line-height: 23px;
        img {
          width: 300px;
        }
        h3 {
          &:first-of-type {
            font-size: 40px;
          }
          font-size: 22px;
          -webkit-text-stroke-width: 1px;
          line-height: 28px;
        }
      }
    }
  }
</style>
