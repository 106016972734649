<template>
  <ModalVideo
          :link-video="linkVideo"
          :show-modal="showModal"
          @close-modal="closeModal"
  />

  <div id="errors-alert2"
       class="alert">
    <h5>
      Error
    </h5>
    <ul>
      <li v-for="(error,i) in errors"
          :key="i">
        {{ error }}
      </li>
    </ul>
  </div>

  <div class="banner-middle">

    <div class="banner-middle__first_part">
      <div class="banner-middle__first_part__column">
        <h2>{{ $t("discover.title1") }}</h2>
        <div class="banner-middle__first_part__column__images">
          <img src="@/assets/img/HowToEnter.png" :alt="$t('discover.title1')">
        </div>
        <ul>
          <li>{{ $t('discover.list1.paragraph1') }}
            <a
              href="https://www.sandbox.game/en/create/game-maker/?r=BRfi9bS3OdLtnlW5wetMY_"
              target="_blank">
            {{ $t('discover.list1.paragraph2') }}
            </a>
          </li>
          <li>{{ $t('discover.list1.paragraph3') }}</li>
          <li>{{ $t('discover.list1.paragraph4') }}</li>
          <li>{{ $t('discover.list1.paragraph5') }}</li>
        </ul>
      </div>
      <div class="banner-middle__first_part__column">
        <h2>{{ $t("discover.title2") }}</h2>
        <div class="banner-middle__first_part__column__images">
          <img src="@/assets/img/GetStarted.png" :alt="$t('discover.title2')">
        </div>
        <ul>
          <li>{{ $t('discover.list2.paragraph1') }}
            <a
              href="https://www.sandbox.game/en/create/game-maker/?r=BRfi9bS3OdLtnlW5wetMY_"
              target="_blank">
            {{ $t('discover.list2.paragraph2') }}
            </a> {{ $t('discover.list2.paragraph3') }}
          </li>
          <li>{{ $t('discover.list2.paragraph4') }}
            <a
              href="https://sandboxgame.gitbook.io/the-game-maker-academy/"
              target="_blank">
            {{ $t('discover.list2.paragraph5') }}
            </a>
            {{ $t('discover.list2.paragraph6') }}
          </li>
          <li>{{ $t('discover.list2.paragraph7') }}
            <a
              href="https://sandboxgame.gitbook.io/the-sandbox"
              target="_blank">
              {{ $t('discover.list2.paragraph8') }}
            </a>
            {{ $t('discover.list2.paragraph9') }}
          </li>
          <li>
            {{ $t('discover.list2.paragraph10') }}
            <a
              href="https://discord.com/invite/cgCFFjj"
              target="_blank">
              {{ $t('discover.list2.paragraph11') }}
            </a> {{ $t('discover.list2.paragraph12') }}
          </li>
          <li>{{ $t('discover.list2.paragraph13') }}</li>
        </ul>
      </div>
    </div>

      <form method="post"
          @submit="checkForm1"
          class="banner-middle__form">
        <div class="banner-middle__form__column">
          <div class="custum-input">
            <input v-model="email1"
                   aria-label="Email"
                   type="email"
                   name="email1"
                   id="email1"
                   placeholder="youremail@mail.com"
                   @focusin="emptyPlaceholder('email1')"
                   @focusout="initPlaceholder('email1')" />
          </div>
        </div>
        <div class="banner-middle__form__column">
          <button class="main-button"
                  type="submit">
            {{ $t("discover.button1") }}
          </button>
        </div>
      </form>

    <div class="banner-middle__image">
      <img src="@/assets/img/blood.png" :alt="$t('discover.title2')">
    </div>

    <div class="banner-middle__second_part">
      <h2>{{ $t("discover.title3") }}</h2>
      <div class="banner-middle__second_part__video">
        <img src="@/assets/img/Discover.png" :alt="$t('discover.title3')">
        <img
                src="@/assets/svg/playVideo.svg"
                alt="play the video"
                class="play-video"
                @click="openVideo('https://www.youtube.com/embed/0Jquhzr4d4k')"
        >
      </div>
      <p>{{ $t("discover.paragraph1") }}</p>
      <p>{{ $t("discover.paragraph2") }}</p>
      <form
              method="post"
              @submit="checkForm2">
        <div class="mt-4 form-group">
          <div class="custum-input">
            <input
                    v-model="email2"
                    aria-label="Email"
                    type="email"
                    name="email2"
                    id="email2"
                    placeholder="youremail@mail.com"
                    @focusin="emptyPlaceholder('email2')"
                    @focusout="initPlaceholder('email2')" />
          </div>
          <button
                  class="main-button"
                  type="submit">
            {{ $t("discover.button1") }}
          </button>
        </div>
      </form>

      <div class="banner-middle__second_part__cubes">
        <img src="@/assets/img/cubes/cube3.png" alt="cube">
        <img src="@/assets/img/cubes/cube4.png" alt="cube">
        <img src="@/assets/img/cubes/cube2.png" alt="cube">
        <img src="@/assets/img/cubes/cube1.png" alt="cube">
        <img src="@/assets/img/cubes/cube5.png" alt="cube">
      </div>
    </div>

    <div class="banner-middle__third_part">
      <h2>{{ $t("discover.title4") }}</h2>
      <h3>{{ $t("discover.subtitle1") }}</h3>
      <div class="banner-middle__third_part__image">
        <img class="banner-middle__third_part__image__desktop" src="@/assets/img/BlogBanner.png" :alt="$t('discover.title4')">
        <img class="banner-middle__third_part__image__tablet" src="@/assets/img/BlogBannerTablet.png" :alt="$t('discover.title4')">
        <img class="banner-middle__third_part__image__mobile" src="@/assets/img/BlogBannerMobil.png" :alt="$t('discover.title4')">
      </div>
    </div>

    <div class="banner-middle__fourth_part">
      <div class="banner-middle__fourth_part__images">
        <img src="@/assets/img/GenericSurvivor.png" :alt="$t('discover.title5')">
        <img src="@/assets/img/Walker1.png" :alt="$t('discover.title5')">
      </div>
      <h2>{{ $t("discover.title5") }}</h2>
      <ul>
        <li>{{ $t('discover.list3.paragraph1') }} <span>{{ $t('discover.list3.paragraph2') }}</span></li>
        <li><span>{{ $t('discover.list3.paragraph3') }}</span> {{ $t('discover.list3.paragraph4') }}</li>
        <li><span>{{ $t('discover.list3.paragraph5') }}</span> {{ $t('discover.list3.paragraph5') }}</li>
      </ul>
      <button>
        <a
          :href="`https://www.sandbox.game/${$i18n.locale}/whitelist/experiences/`"
          target="_blank">
          {{ $t("discover.button2") }}
        </a>
      </button>
    </div>
  </div>
</template>

<script>
import ModalVideo from "@/components/ModalVideo"

export default {
  name: "Discover",
  components: {
    ModalVideo
  },
  data() {
    return {
      showModal: false,
      linkVideo: "",
      errors: [],
      email1: '',
      email2: ''
    }
  },
  methods: {
    openVideo(linkVideo) {
      this.linkVideo = linkVideo;
      this.showModal = true;
    },
    closeModal() {
      this.showModal = false;
    },
    emptyPlaceholder: function (id) {
      console.log(id);
      document.getElementById(id).placeholder = ''
    },
    initPlaceholder: function (id) {
      console.log(id);
      document.getElementById(id).placeholder = 'youremail@mail.com'
    },
    checkForm1: function (e) {
      e.preventDefault()
      document.getElementById('email1').style.background = ''
      this.errors = []
      var err = 0
      let currObj = this
      if (this.email1 === '') {
        err = 1
        this.errors.push('Email is required.')
        document.getElementById('email1').style.background = '#D4212780'
        document.getElementById('email1').style.color = '#ffffff'
      }
      if (err === 0) {
        const headers = {
          'Content-Type': 'text/plain'
        }
        this.axios.post(
                'https://sandbox.pragmea.io/Webservices/src/mailerlite.php?sA=fb653c7d2f1fc6269ca02429a45e0bcf',
                { action: 'addSubscriber', email: this.email1, segment: '109278461' },
                { headers }
        ).then((response) => {
          if (response.data.error) {
            currObj.errors.push(response.data.error.message)
            document.getElementById('errors-alert2').style.opacity = '1'
            setTimeout(function () {
              document.getElementById('errors-alert2').style.opacity = '0'
            }, 5000)
          } else {
            window.location = 'https://www.sandbox.game/' + this.$i18n.locale + '/create/game-maker/'
          }
        }).catch(function (error) {
          currObj.errors.push(error)
          document.getElementById('errors-alert2').style.opacity = '1'
          setTimeout(function () {
            document.getElementById('errors-alert2').style.opacity = '0'
          }, 5000)
        })
      }
    },
    checkForm2: function (e) {
      e.preventDefault()
      document.getElementById('email2').style.background = ''
      this.errors = []
      var err = 0
      let currObj = this
      if (this.email2 === '') {
        err = 1
        this.errors.push('Email is required.')
        document.getElementById('email2').style.background = '#D4212780'
        document.getElementById('email2').style.color = '#ffffff'
      }
      if (err === 0) {
        const headers = {
          'Content-Type': 'text/plain'
        }
        this.axios.post(
                'https://sandbox.pragmea.io/Webservices/src/mailerlite.php?sA=fb653c7d2f1fc6269ca02429a45e0bcf',
                { action: 'addSubscriber', email: this.email2, segment: '109278461' },
                { headers }
        ).then((response) => {
          if (response.data.error) {
            currObj.errors.push(response.data.error.message)
            document.getElementById('errors-alert2').style.opacity = '1'
            setTimeout(function () {
              document.getElementById('errors-alert2').style.opacity = '0'
            }, 5000)
          } else {
            window.location = 'https://www.sandbox.game/' + this.$i18n.locale + '/create/game-maker/'
          }
        }).catch(function (error) {
          currObj.errors.push(error)
          document.getElementById('errors-alert2').style.opacity = '1'
          setTimeout(function () {
            document.getElementById('errors-alert2').style.opacity = '0'
          }, 5000)
        })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  .alert {
    position: fixed;
    top: 70px;
    right: 150px;
    width: calc(100% - 300px);
    opacity: 0;
    transition: opacity .5s;
    padding: 0 20px;
    font-size: 20px;
    color: white;
    background: rgba(212, 33, 39, 0.75);
    border-radius: 8px;
    z-index: 5;
  }
  .banner-middle {
    background-color: $grey-12;
    @include flex(column, center, center);
    padding: 100px 0;
    position: relative;
    h2, h3 {
      font-size: 40px;
      font-weight: 1000;
      line-height: 50px;
      margin: 0;
      text-transform: uppercase;
    }
    h3 {
      color: $red;
    }
    button {
      margin-top: 20px;
      display: inline-block;
      background-color: $red;
      z-index: 1;
      color: white;
      border: none;
      font-weight: bold;
      text-transform: uppercase;
      padding: 15px 62px;
      border-radius: 60px;
      font-size: 15px;
      cursor: pointer;
      text-decoration: none;
      transition: 200ms;
      &:hover {
        background-color: white;
        color: $gray2;
      }
    }
    a {
      color: white;
      text-decoration: none;
    }


    .custum-input input {
      background-color: transparent;
      border-radius: 2.3em;
      border: solid $white-1 1px;
      color: $white-1;
      font-size: 1em;
      text-align: center;
      width: 100%;
      padding: 8px 0;
      &::placeholder {
        color: $white-1;
        opacity: 1; /* Firefox */
      }
    }
    .custum-input {
      width: 100% !important;
    }

    .custum-input,
    .form-group {
      width: fit-content;
    }

    &__form {
      @include flex(row, flex-start, center);
      margin-bottom: 120px;
      width: 100%;

      button {
        margin-top: 0;
        width: 100%;
      }
      &__column {
        text-align: left;
        width: 100%;
        max-width: 475px;
        &:first-of-type {
          margin-right: 50px;
        }
        &:last-of-type {
          margin-left: 50px;
        }

        .custum-input input {
          padding: 15px 0;
        }
      }
    }
    &__first_part {
      z-index: 1;
      width: 100%;
      max-width: 1335px;
      @include flex(row, flex-start, center);
      &__column {
        text-align: left;
        width: 50%;
        max-width: 475px;
        &:first-of-type {
          margin-right: 50px;
        }
        &:last-of-type {
          margin-left: 50px;
        }
        &__images {
          position: relative;

          img {
            margin: 20px 0;
            max-width: 475px;
          }
        }
        &__button {
          @include flex(column, center, center);
        }
        ul {
          padding-left: 25px ;
        }
        li {
          margin-bottom: 23px;
          font-size: 15px;
          line-height: 23px;
          font-weight: 500;
          span:first-of-type {
            color: $red;
          }
          a {
            color: $red;
          }
        }
        a {
          margin-top: 0;
        }
      }

    }

    &__image {
      position: relative;
      width: 100%;
      img {
        position: absolute;
        bottom: -200px;
        right: -150px;
      }
    }

    &__second_part {
      margin-bottom: 120px;
      position: relative;
      z-index: 1;
      width: 100%;
      max-width: 1335px;
      @include flex(column, center, center);

      h2{
        margin-bottom: 25px;
      }

      p{
        width: calc(56.5%);
        font-size: 15px;
        line-height: 23px;
        font-weight: 600;
      }
      &__video {
        width: calc(55.5%);
        position: relative;

        img {
          width: 100%;
        }

        .play-video {
          width: 60px;
          height: 60px;
          position: absolute;
          top: calc(50% - 30px);
          left: calc(50% - 30px);
          cursor: pointer;
        }
      }

      &__cubes {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: -1;
        img {
          position: absolute;
          &:nth-of-type(1) {
            width: 100px;
            top: 40%;
            left: 8%;
          }
          &:nth-of-type(2) {
            width: 140px;
            top: 15%;
            right: 2%;
          }
          &:nth-of-type(3) {
            width: 160px;
            top: 63%;
            right: 8%;
          }
          &:nth-of-type(4) {
            width: 120px;
            top: 80%;
            left: 2%;
          }
          &:nth-of-type(5) {
            width: 80px;
            top: 93%;
            left: 30%;
          }
        }
      }
    }

    &__third_part {
      margin-bottom: 120px;
      z-index: 1;
      width: 100%;
      max-width: 1335px;
      text-align: center;
      @include flex(column, center, center);

      p{
        width: calc(56.5%);
        font-size: 15px;
        line-height: 23px;
        font-weight: 600;
      }

      &__image {
        position: relative;

        & > img {
          width: 100%;
        }

        &__mobile, &__tablet {
          display:none;
        }
      }
    }

    &__fourth_part {
      margin-top: 50px;
      margin-bottom: 120px;
      z-index: 1;
      width: 100%;
      max-width: 1335px;
      position: relative;
      @include flex(column, center, center);

       &__images {
         position: absolute;
         width: 100%;
         @include flex(row, center, space-between);
         img{
         }

       }
      ul {
        width: calc(56.5%);
        padding-left: 25px ;
      }
      li {
        margin-bottom: 23px;
        font-size: 20px;
        line-height: 28px;
        font-weight: 500;

        span:first-of-type {
          font-weight: 800;
        }
      }
    }
  }
  @include breakpoint($max: 1500) {
    .banner-middle {
      &__second_part {
        min-width: 900px;
        &__video {
          width: 100%;
          margin-top: 40px;
          max-width: 500px;
        }
      }
    }
  }
  @include breakpoint($max: 1100) {
    .banner-middle {
      &__first_part, &__form {
        @include flex(column, center, center);
        &__column {
          text-align: left;
          width: 100%;
          max-width: 475px;

          input {
            margin-bottom: 10px;
          }

          &:first-of-type {
            margin-right: 0;
          }

          &:last-of-type {
            margin-left: 0;
          }
          &__images {
            width: 100%;
          }
        }
      }

      &__second_part {
        &__cubes {
          display: none;
        }
      }

      &__third_part {
        &__image {
          &__mobile, &__desktop {
            display: none;
          }
          &__tablet {
            display: block;
          }
        }
      }
    }
  }
  @include breakpoint($max: 767) {
    .banner-middle {
      &__fourth_part {
        ul {
          width: 80%;
        }
        &__images {
          display: none;
        }
      }
    }
  }
  @include breakpoint($max: 500) {
    .banner-middle {
      h2 {
        font-size: 30px;
        line-height: 36px;
      }

      &__third_part {
        &__image {
          &__tablet, &__desktop {
            display: none;
          }
          &__mobile {
            display: block;
          }
        }
      }
    }
  }
</style>
