<template>
  <router-view/>
</template>

<style lang="scss">
body {
  margin: 0;
  background-color: #14181d;
  overflow: hidden;
}
#app {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: white;
    font-size: 15px;
    line-height: 23px;
    overflow-x: hidden;
    overflow-y: hidden;
    font-family: 'Montserrat', 'Helvetica', 'Arial', sans-serif;
}
a, p, h1, h2, h3, h4, button {
    font-family: 'Montserrat', 'Helvetica', 'Arial', sans-serif;
}
/* width */
::-webkit-scrollbar {
width: 7px;
}

/* Track */
::-webkit-scrollbar-track {
background: $grey-1;
}

/* Handle */
::-webkit-scrollbar-thumb {
background: $grey-5;
}
* {
    box-sizing: border-box;
}
</style>
