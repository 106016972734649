<template>
  <div v-if="showModal" class="modal-video">
    <img
      src="@/assets/svg/times-solid-white.svg"
      alt="cross"
      @click="closeModal"
    >
    <div class="modal-video__container">
      <iframe width="1229" height="691" :src="linkVideo" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
    </div>
  </div>
</template>

<script>
export default {
  name: "ModalVideo",
  props: {
    linkVideo: { type: String, default: "" },
    showModal: { type: Boolean, default: false }
  },
  methods: {
    closeModal() {
      this.$emit("close-modal");
    }
  }
}
</script>

<style lang="scss" scoped>
  .modal-video {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.76);
    z-index: 5;
    img {
      position: absolute;
      right: 20px;
      top: 20px;
      width: 40px;
      height: 40px;
      transition: 200ms;
      cursor: pointer;
      &:hover {
        transform: rotateZ(90deg);
      }
    }
    &__container {
      width: 100%;
      height: 100%;
      @include flex(row, center, center);
    }
  }
  @include breakpoint($max: 1279) {
    .modal-video {
      iframe {
        width: 100%;
        height: auto;
        min-height: 360px;
      }
    }
  }
  @include breakpoint($max: 600) {
    .modal-video {
      iframe {
        min-height: 220px;
      }
    }
  }
</style>