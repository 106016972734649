{
  "meta": {
    "Home": {
      "title": "The Sandbox - The Walking Dead",
      "description": ""
    },
    "favicon": "/favicon.ico"
  },
  "bannerTop": {
    "title": "The Walking DEAD",
    "subtitle1": "더 샌드박스를 점령합니다.",
    "subtitle2": "",
    "paragraph1": "더 워킹 데드는 완전 새로운 인게임 경험을 가져옵니다.",
    "paragraph3": "",
    "paragraph2": "게임메이커를 활용하여 자신만의 게임을 만들거나 다른 플레이어가 만든 경험을 플레이해보세요."
  },
  "login": {
    "button": "로그인 혹은 계정 만들기",
    "descriptionRegular1": "지금 등록하면 게임 출시 후 더 워킹 데드 생존 킷을 받게 됩니다.",
    "descriptionBold": "",
    "descriptionRegular2": ""
  },
  "bannerMiddle": {
    "title1": "게임을 플레이하고, 만들고,",
    "title2": "소유하고, 다스려보세요.",
    "subtitle1": "플레이어들이 만든 버츄얼 세계로 환영합니다.",
    "paragraph1": "다른 플레이어들과 함께 다양한 게임 경험을 온라인으로 경험할 수 있습니다. 3D 복셀 아트 애니메이션 소프트웨어를 사용하여 게임 아이템을 만들고, 게임 메이커 툴을 통해 자신 만의 특별한 게임 경험을 만들 수 있습니다. 또한, 마켓플레이스에서 게임 아이템들을 자유롭게 구매하고 판매할 수 있습니다!",
    "paragraph6": "더 샌드박스에서는, 무궁한 상상력을 실현시킬 수 있습니다.",
    "button1": "지금 시작하세요.",
    "button1A": "Discover the map >",
    "title3": "탐험하고 발견하세요",
    "subtitle2": "워킹 데드의 세계를 경험해보세요",
    "subtitle3": "상상을 실현하세요",
    "subtitle4": "",
    "subtitle3A": "The Walking Dead LANDs",
    "subtitle5": "소유한 아이템들로 자신의 LAND를 꾸며보세요.",
    "paragraph2": "워킹 데드의 20개가 넘는 게임 애셋을 활용하여 자신만의 게임을 만들어보세요.",
    "paragraph2A": "Own virtual real estate in the world of The Walking Dead. Build your own experiences with exclusive The Walking Dead assets.",
    "paragraph3": "코믹북에서 나오는 캐릭터들과 아이템들을 차지하세요.",
    "paragraph5": "멋진 게임 아이템들을 발견하세요"
  },
  "partners": {
    "title1": "더 샌드박스 파트너 브랜드 및 IP",
    "title2": ""
  },
  "footer": {
    "title": "Follow us.",
    "text": "Based on the comic book The Walking Dead by Robert Kirkman, Tony Moore and Charlie Adlard. The Walking Dead is a registered or otherwise protected trademark owned by Robert Kirkman, LLC. Skybound is a registered or otherwise protected trademark owned by Skybound, LLC. Uses with permission.",
    "socialIcons": [
      {
        "name": "discord",
        "url": "https://discordapp.com/invite/vAe4zvY "
      },
      {
        "name": "telegram",
        "url": "https://t.me/sandboxgame"
      },
      {
        "name": "medium",
        "url": "https://medium.com/sandbox-game"
      },
      {
        "name": "twitter",
        "url": "https://twitter.com/TheSandboxGame"
      },
      {
        "name": "instagram",
        "url": "https://www.instagram.com/thesandboxgame/"
      },
      {
        "name": "facebook",
        "url": "https://www.facebook.com/TheSandboxGame/"
      },
      {
        "name": "youtube",
        "url": "https://www.youtube.com/user/TheSandboxGame"
      },
      {
        "name": "twitch",
        "url": "https://www.twitch.tv/thesandboxgame"
      },
      {
        "name": "reddit",
        "url": "https://www.reddit.com/r/TheSandboxGaming/"
      }
    ]
  }
}
