<template>
  <div class="banner-middle">
    <div class="banner-middle__content">
      <div class="banner-middle__content__images">
        <img src="@/assets/img/JoinUs.png" alt="Join Us">
        <img src="@/assets/img/Walker3.png" alt="walker">
      </div>
      <div class="banner-middle__content__intro">
        <h2>{{ $t("joinUs.title") }}</h2>
        <p>{{ $t("joinUs.paragraph") }}</p>
        <a
                :href="`https://community.sandbox.game/${$i18n.locale}/`"
                target="_blank">{{ $t("joinUs.button") }}</a>
      </div>
      <div class="banner-middle__content__images">
        <img src="@/assets/img/Walker2.png" alt="walker">
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: "JoinUs"
}
</script>

<style lang="scss" scoped>
  .banner-middle {
    background-color: $gray2;
    @include flex(column, center, center);
    z-index: 2;
    position: relative;
    h2{
      font-weight: 1000;
      font-size: 33px;
      line-height: 38px;
      text-transform: uppercase;
      margin: 0;
    }
    p {
      font-size: 18px;
      font-weight: 600;
      line-height: 25px;
    }
    a {
      display: inline-block;
      background-color: $red;
      z-index: 1;
      color: white;
      border: none;
      font-weight: bold;
      text-transform: uppercase;
      padding: 15px 62px;
      border-radius: 60px;
      font-size: 19px;
      cursor: pointer;
      text-decoration: none;
      transition: 200ms;
      &:hover {
        background-color: white;
        color: $gray2;
      }
    }
    &__content {
      z-index: 1;
      width: 100%;
      height: 355px;
      max-width: 1335px;
      @include flex(row, center, space-between);
      &__intro {
        width: calc(50% - 50px);
        p {
          line-height: 23px;
          font-size: 15px;
          margin-bottom: 30px;
        }
      }
      &__images {
        &:nth-of-type(1) {
          margin-bottom: -27px ;
        }
        &>img:nth-of-type(2) {
          display: none;
        }
        &:last-of-type>img {
          position: absolute;
          top: 0;
          pointer-events: none;
          width: 220px;
          margin-top: 120px;
          right: 2%;
          z-index: -1;
        }
      }
    }
  }
  @include breakpoint($max: 1100) {
    .banner-middle {
      &__content {
        height: 225px;
        &__intro {
          width: calc(60% - 50px);
          p {
            line-height: 23px;
            font-size: 15px;
            margin-bottom: 30px;
          }
        }
        &__images {
          height: 100%;
          @include flex(row, flex-end, center);

          &>img:nth-of-type(2), &>img:nth-of-type(1) {
              margin-bottom: -4px ;
              width: 100%;
          }
          &:last-of-type>img {
            display:none;
          }
        }
      }
    }
  }
  @include breakpoint($max: 768) {
    .banner-middle {
      &__content {
        p {
          display:none;
        }
        &__images {
          &>img:nth-of-type(2) {
            display: block;
          }
          &>img:nth-of-type(1) {
            display: none;
          }

          &>img:nth-of-type(2), &>img:nth-of-type(1) {
            margin-bottom: 20px ;
            width: 100%;
          }
        }
        &__intro {
          margin-left: 10px;
          width: calc(80% - 10px);
        }
      }
      a {
        margin-top: 20px;
        padding: 10px 31px;
        font-size: 15px;
      }
    }
  }
</style>
