{
  "meta": {
    "Home": {
      "title": "The Sandbox - The Walking Dead",
      "description": ""
    },
    "favicon": "/favicon.ico"
  },
  "bannerTop": {
    "title": "The Walking DEAD",
    "subtitle1": "仮想空間へ襲撃",
    "subtitle2": "",
    "paragraph1": "『ザ・ウォーキング・デッド』x『The Sandbox』で何が起きるのか",
    "paragraph3": "",
    "paragraph2": "Game Makerで自分の世界の作成したり、LANDを周って楽しみましょう。"
  },
  "login": {
    "button": "ログイン/アカウント作成",
    "descriptionRegular1": "サインアップをして『ウォーキング・デッド』限定のサバイバーバックを無料ＧＥＴ。",
    "descriptionBold": "",
    "descriptionRegular2": ""
  },
  "bannerMiddle": {
    "title1": "遊ぶ、作る、",
    "title2": "所有する、統治する",
    "subtitle1": "プレイヤーが作る仮想の世界",
    "paragraph1": "VoxEditで3Dボクセルアニメーション制作、Game Makerでゲーム体験の創造、そして販売が楽しい。",
    "paragraph6": "『The Sandbox』は無限の世界",
    "button1": "ゲームを制作を始める",
    "button1A": "マップを見る >",
    "title3": "探索して発見する",
    "subtitle2": "今までにない『ザ・ウォーキング・デッド』の世界がここに",
    "subtitle3": "想像と制作",
    "subtitle4": "",
    "subtitle3A": "ウォーキング・デッド LAND",
    "subtitle5": "これらのアセットを使って、あなたの「LAND」を作ってみましょう。",
    "paragraph2": "ウォーカーやサバイバーを含む20種類以上のアセットを使って、自分だけの『ザ・ウォーキング・デッド』の世界を作りましょう。",
    "paragraph2A": "『ウォーキング・デッド』の世界のデジタル不動産を所有することができます。『ウォーキング・デッド』の限定アセットを使って、自分だけの体験を作りましょう。",
    "paragraph3": "ウォーカー、ミショーン、ルシールなど、コミックの世界に登場するキャラクターや装飾要素を見つけよう。",
    "paragraph5": "メインASSETの発見"
  },
  "partners": {
    "title1": "『The Sandbox』のパートナー",
    "title2": ""
  },
  "footer": {
    "title": "Follow us.",
    "text": "Based on the comic book The Walking Dead by Robert Kirkman, Tony Moore and Charlie Adlard. The Walking Dead is a registered or otherwise protected trademark owned by Robert Kirkman, LLC. Skybound is a registered or otherwise protected trademark owned by Skybound, LLC. Uses with permission.",
    "socialIcons": [
      {
        "name": "discord",
        "url": "https://discordapp.com/invite/vAe4zvY "
      },
      {
        "name": "telegram",
        "url": "https://t.me/sandboxgame"
      },
      {
        "name": "medium",
        "url": "https://medium.com/sandbox-game"
      },
      {
        "name": "twitter",
        "url": "https://twitter.com/TheSandboxGame"
      },
      {
        "name": "instagram",
        "url": "https://www.instagram.com/thesandboxgame/"
      },
      {
        "name": "facebook",
        "url": "https://www.facebook.com/TheSandboxGame/"
      },
      {
        "name": "youtube",
        "url": "https://www.youtube.com/user/TheSandboxGame"
      },
      {
        "name": "twitch",
        "url": "https://www.twitch.tv/thesandboxgame"
      },
      {
        "name": "reddit",
        "url": "https://www.reddit.com/r/TheSandboxGaming/"
      }
    ]
  }
}
