<template>
  <div class="landing">
    <HeaderLanding />
    <BannerTopGJ />
    <Announcing />
    <Prices />
    <Discover />
    <JoinUs />
    <Footer />
  </div>
</template>

<script>
  import HeaderLanding from "@/components/HeaderLanding.vue"
  import BannerTopGJ from "@/components/BannerTopGJ.vue"
  import Announcing from "@/components/Announcing.vue"
  import Prices from "@/components/Prices.vue"
  import Discover from "@/components/Discover.vue"
  import JoinUs from "@/components/JoinUs.vue"
  import Footer from "@/components/Footer.vue"

export default {
  name: 'Landing',
  components: {
    HeaderLanding,
    BannerTopGJ,
    Announcing,
    Prices,
    Discover,
    JoinUs,
    Footer
  }
}
</script>

<style lang="scss" scoped>
  .landing {
    height: 100vh;
    overflow-x: hidden;
    overflow-y: auto;
  }
</style>
