<template>
  <div class="banner-middle">

    <div class="banner-middle__content">
      <div class="banner-middle__content__infos">
        <h2>{{ $t("prices.title") }}</h2>
        <p class="banner-middle__content__infos__first_part">
          {{ $t("prices.paragraph1") }} <span>{{ $t("prices.paragraph2") }}</span><br><span>{{ $t("prices.paragraph3") }}</span>
        </p>
        <table>
          <tr>
            <th>{{ $t("prices.rank1") }}</th>
            <td>{{ $t("prices.price1") }}</td>
          </tr>
          <tr>
            <th>{{ $t("prices.rank2") }}</th>
            <td>{{ $t("prices.price2") }}</td>
          </tr>
          <tr>
            <th>{{ $t("prices.rank3") }}</th>
            <td>{{ $t("prices.price3") }}</td>
          </tr>
          <tr>
            <th>{{ $t("prices.rank4") }}</th>
            <td>{{ $t("prices.price4") }}</td>
          </tr>
        </table>

        <p class="banner-middle__content__infos__second_part">
          {{ $t("prices.paragraph4") }} <span>{{ $t("prices.paragraph5") }}</span>
        </p>
        <p class="banner-middle__content__infos__third_part">{{ $t("prices.paragraph6") }}</p>
      </div>

      <div class="banner-middle__content__coins">
        <img src="@/assets/img/SANDCoin3.png" alt="sandcoin">
        <img src="@/assets/img/SANDCoin3.png" alt="sandcoin">
        <img src="@/assets/img/SANDCoin3.png" alt="sandcoin">
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: "Prices"
}
</script>

<style lang="scss" scoped>
  .banner-middle {
    background-color: $grey-12;
    @include flex(column, center, center);
    padding: 30px 0;
    position: relative;
    h2 {
      font-size: 40px;
      font-weight: 1000;
      line-height: 50px;
      margin: 0 0 41px 0;
      text-align: center;
      text-transform: uppercase;
    }
    &__content {
      position: relative;
      z-index: 1;
      width: 100%;
      max-width: 1335px;

      @include flex(column, center, center);
      &__infos {
        text-align: center;
        font-weight: 600;
        @include flex(column, center, center);
        &__first_part {
          margin-bottom: 20px;
          font-weight: 1000;
          color: white;
          font-size: 15px;
          line-height: 23px;

          & > span:first-of-type {
            color: $red;
          }

          & > span:last-of-type {
            font-weight: 600;
          }
        }

        &__second_part, &__third_part {
          font-weight: 600;
          color: white;
          font-size: 15px;
          line-height: 23px;
          margin: 0;

          & > span:first-of-type {
            font-weight: 800;
          }
        }
        th,td {
          white-space: pre;
          font-size: 25px;
          padding-bottom: 15px;
          text-align: left;
        }
        th {
          color: $red;
          padding-right: 40px;
        }
        td {
          padding-left: 40px;
        }

        table {
          max-width: 600px;
        }
      }
      &__images {
        width: calc(55.5%);
        position: relative;
        img {
          width: 100%;
        }
      }
      &__coins {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: -1;
        img {
          position: absolute;
          &:nth-of-type(1) {
            width: 118px;
            transform: rotate(32deg);
            top: 5%;
            left: 13%;
          }
          &:nth-of-type(2) {
            width: 118px;
            transform: rotate(-40deg);
            top: 15%;
            right: 13%;
          }
          &:nth-of-type(3) {
            width: 92px;
            top: 70%;
            left: 20%;
          }
        }
      }
    }
  }
  @include breakpoint($max: 1500) {
    .banner-middle {
      &__content {
        min-width: 900px;
      }
    }
  }
  @include breakpoint($max: 1100) {
    .banner-middle {
      &__content {
        min-width: auto;
        width: 86%;
        @include flex(column, center, center);
        &__titles {
          text-align: center;
          h2 {
            font-size: 35px;
            line-height: 40px;
          }
          h3 {
            font-size: 22px;
          }
        }
        &__images {
          width: 100%;
          margin-top: 40px;
          max-width: 500px;
        }
        &__coins {
          display: none;
        }
      }
    }
  }
  @include breakpoint($max: 500) {
    .banner-middle {
      h2 {
        font-size: 30px;
        line-height: 36px;
      }
    }
  }
</style>
